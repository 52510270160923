import React from 'react';
import { Application } from '~/pages/ApplicationDetails/data/useApplication';

export type Process =
  | 'decline'
  | 'issuePreOffer'
  | 'passScreening'
  | 'additionalDocumentRequest'
  | 'issueFinalOffer'
  | 'waitlisted'
  | 'deferralRequest'
  | 'deferralRequestPreOffer'
  | 'dismissDocumentRequest';

type State = {
  application: Application;
  startProcess: (process: Process) => void;
  completeProcess: () => void;
  activeProcess: Process | undefined;
  nextProcess: Process | undefined;
  setNextProcess: (action: Process) => void;
};
const context = React.createContext<State | undefined>(undefined);

type AppProcessingProviderProps = {
  application: Application;
  children: React.ReactNode;
};
export function AppProcessingProvider(props: AppProcessingProviderProps) {
  const [activeProcess, setActiveProcess] = React.useState<Process | undefined>(undefined);
  const [nextProcess, setNextProcess] = React.useState<Process | undefined>(undefined);

  const value = React.useMemo<State>(() => {
    return {
      application: props.application,
      activeProcess,
      startProcess: (process: Process) => setActiveProcess(process),
      completeProcess: () => {
        setNextProcess(undefined);
        setActiveProcess(undefined);
      },
      nextProcess,
      setNextProcess: (action: Process) => setNextProcess(action),
    };
  }, [activeProcess, props.application, nextProcess]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}
export function useAppProcessing() {
  const state = React.useContext(context);
  if (!state) throw new Error('useAppProcessing should be used within <useAppProcessingProvider />');
  return state;
}
