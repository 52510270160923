import React from 'react';
import { Notice } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

export const documentRequestModeNoticeMessage =
  'Document request mode is now active. Please select the documents you wish to request.';

export function DocumentRequestModeNotice() {
  const { activeProcess } = useAppProcessing();
  const [showNotice, setShowNotice] = React.useState(true);

  if (activeProcess !== 'additionalDocumentRequest') return null;

  if (!showNotice) return null;

  return (
    <Notice intent="primary" width="fill">
      {documentRequestModeNoticeMessage}
      <Notice.CloseButton onClick={() => setShowNotice(false)} />
    </Notice>
  );
}
