import { ChevronLeftOutlineIcon } from '@applyboard/ui-icons';
import { ButtonLink, Flex, Text } from '@applyboard/crystal-ui';

type BackButtonProps = {
  title: string;
  href: string;
};
export function BackButton(props: BackButtonProps) {
  return (
    <Flex direction="row" align="center" gap={2}>
      <ButtonLink
        href={props.href}
        aria-label={`Back to ${props.title}`}
        emphasis="transparent"
        leadIcon={ChevronLeftOutlineIcon}
        size="md"
      />
      <Text variant="labelL" intent="secondary" contrast="mid">
        {props.title}
      </Text>
    </Flex>
  );
}
