import { useQuery } from '@tanstack/react-query';
import {
  ListApplicationsResponse,
  ApplicationSortableFields,
  ApplicationsListResource,
} from '@ab/ams-school-api/types/private/v1';

import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import { IsNullOrUndefined, hasKey } from '~/utils';

export type ApplicationSearchQuery = {
  'filter[q]'?: string;
  'filter[status]'?: string[];
  'filter[nationality]'?: string[];
  'filter[programId]'?: string[];
  'filter[intakeTermId]'?: string[];
  'filter[submittedAt][from]'?: string;
  'filter[submittedAt][to]'?: string;
  'page[size]': number;
  'page[number]': number;
  sort?: ApplicationSortableFields;
};

export type SortableFields = ApplicationSortableFields;
export type Application = ApplicationsListResource['attributes'] & { id: string };

const defaultValue: Application[] = [];

export function useApplications(
  query: ApplicationSearchQuery,
  options?: { onSuccess?: (data: Application[], total: number) => void }
) {
  const request = useRequest();
  const { isLoading, isFetching, error, data, isPreviousData, isError } = useQuery(
    ['applications', query],
    async () => {
      const response = await request<ListApplicationsResponse>(
        `${config.apiHost}/private/v1/applications?${convertToUrlSearchParams(query)}`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );
      const data = response.data;
      const applications = data.map((app) => {
        const attrs = app.attributes;
        return {
          ...attrs,
          id: app.id,
        } as Application;
      });

      // trigger on success
      options?.onSuccess?.(applications, response.meta.pagination.total);

      return {
        // as we are using keepPreviousData, we need to know the result is based on which page
        pagination: {
          pageNumber: response.meta.pagination.pageNumber,
          pageSize: response.meta.pagination.pageSize,
        },
        applications,
        totalApplications: response.meta.pagination.total,
      };
    },
    {
      keepPreviousData: true,
    }
  );

  return {
    isPreviousApplicationsData: isPreviousData,
    applicationPagination: data?.pagination,
    applications: data?.applications || defaultValue,
    applicationsError: error,
    isApplicationsError: isError,
    isFetchingApplications: isFetching,
    isLoadingApplications: isLoading,
    totalApplications: data?.totalApplications ?? 0,
  };
}

function isExpectedResponse(res: unknown): res is ListApplicationsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res) && Array.isArray(res.data));
}

const convertToUrlSearchParams = (params: ApplicationSearchQuery, baseKey?: string) => {
  const queryParams = {} as Record<string, string>;
  Object.entries(params).forEach(([k, v]) => {
    const key = (baseKey && `${baseKey}[${k}]`) || k;
    if (IsNullOrUndefined(v)) {
      return;
    }
    if (typeof v === 'string' && v.length > 0) {
      queryParams[key] = v;
    } else if (typeof v === 'number') {
      queryParams[key] = `${v}`;
    } else if (typeof v === 'boolean' && v) {
      queryParams[key] = 't';
    } else if (Array.isArray(v) && v.length > 0) {
      queryParams[key] = v.join(',');
    }
  });
  return new URLSearchParams(queryParams);
};
