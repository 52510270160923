import React from 'react';
import { Dialog, Button, Text, Divider } from '@applyboard/crystal-ui';
import { useAppProcessing, Process } from '~/pages/ApplicationDetails/context/';
import { AdditionalDocumentsRequestDetails } from '../AdditionalDocumentRequest/AdditionalDocumentsRequestDetails';
import { usePassScreeningActivity } from '../passScreening';

type UiMessages = {
  capitalizedTitle: string;
  title: string;
  nextState: string;
};

export type SupportedNextProcess = Extract<
  Process,
  'issuePreOffer' | 'issueFinalOffer' | 'waitlisted' | 'passScreening' | 'decline'
>;

function isSupportedNextProcess(process: Process): process is SupportedNextProcess {
  return ['issuePreOffer', 'issueFinalOffer', 'waitlisted', 'passScreening', 'decline'].includes(process);
}

export const dismissMessageMap: Record<SupportedNextProcess, UiMessages> = {
  issuePreOffer: {
    capitalizedTitle: 'Issue Pre-Offer',
    title: 'issue pre-Offer',
    nextState: 'pre-offer',
  },
  issueFinalOffer: {
    capitalizedTitle: 'Issue Final Offer',
    title: 'issue final offer',
    nextState: 'final offer',
  },
  waitlisted: {
    capitalizedTitle: 'Waitlisted',
    title: 'waitlisted',
    nextState: 'waitlisted',
  },
  passScreening: {
    capitalizedTitle: 'Pass Screening',
    title: 'pass screening',
    nextState: 'assessment',
  },
  decline: {
    capitalizedTitle: 'Decline',
    title: 'decline',
    nextState: 'declined',
  },
};

export function DismissDocumentRequestsDialog() {
  const [isBusy, setIsBusy] = React.useState(false);
  const { startProcess, completeProcess, application, nextProcess } = useAppProcessing();
  const { submitPassScreening } = usePassScreeningActivity();

  const onProceed = React.useCallback(() => {
    if (nextProcess === 'passScreening') {
      setIsBusy(true);
      submitPassScreening().finally(() => setIsBusy(false));
      completeProcess();
      return;
    }
    if (nextProcess) {
      startProcess(nextProcess);
    }
  }, [nextProcess, startProcess, submitPassScreening, completeProcess]);

  const additionalDocumentRequest = application.meta.additionalDocumentRequest!;
  if (!additionalDocumentRequest) {
    return null;
  }

  if (!nextProcess || !isSupportedNextProcess(nextProcess)) {
    return null;
  }

  const message = dismissMessageMap[nextProcess];

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open && !isBusy) completeProcess();
      }}
    >
      <Dialog.Heading>{message.capitalizedTitle}</Dialog.Heading>
      <Dialog.Content>
        <Text>
          {`Are you sure you want to ${message.title}? This action will override and dismiss your request for any pending
          documents. The applicant will not be expected to submit pending documents and the application will move
          forward to ${message.nextState}.`}
        </Text>
        <Divider />
        <AdditionalDocumentsRequestDetails additionalDocumentRequest={additionalDocumentRequest} onlyPendings />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button intent="primary" width="fill" onClick={() => onProceed()}>
          {`Yes, continue to ${message.nextState}`}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
