import React from 'react';
import { Dialog, Button, FileUpload, FileInfo, FileList, useToasts } from '@applyboard/crystal-ui';
import { PlusOutlineIcon } from '@applyboard/ui-icons';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { useApplicationFileUploadContext } from '~/pages/ApplicationDetails/context/ApplicationFileUploadProvider';
import { useUpdateApplication } from '~/pages/ApplicationDetails/data/useUpdateApplication';
import { FileUploadFieldValue } from '../../../types';
import { has } from 'lodash';
import { nanoid } from 'nanoid';
import { DocumentType } from 'applications-types-lib';

export function UploadAdditionalDocumentsDialog() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileInfo[]>([]);
  const { application } = useAppProcessing();
  const toast = useToasts();
  const { addPendingUpload, pendingFileUploadState, deleteUploadingFile, addUploadedFiles } =
    useApplicationFileUploadContext();

  const { isUpdatingApplication, updateApplicationAsync } = useUpdateApplication({
    id: application.id,
  });

  function onRemoveFile(fileId: string) {
    setFiles(files.filter((f) => f.id !== fileId));
    deleteUploadingFile(fileId);
  }

  function isTransformedValue(value: FileUploadFieldValue | FileInfo): boolean {
    return has(value, 'hash') && has(value, 'sectionReference') && has(value, 'type');
  }

  function transformValue(value: (FileUploadFieldValue | FileInfo)[]) {
    const newFiles = value
      .filter((item) => {
        if (isTransformedValue(item)) return false;
        const fileInfo = item as FileInfo;
        const { file } = fileInfo;
        if (files.find((f) => f.file === file)) return false;
        return true;
      })
      .map((item) => {
        const fileInfo = item as FileInfo;
        const { file } = fileInfo;
        const id = nanoid();
        const newFileInfo: FileInfo = {
          ...fileInfo,
          id,
        };
        const fileUploadData = {
          contentType: file.type,
          file: file,
          fileName: file.name,
          hash: nanoid() as string,
          type: 'SCHOOL_ADDITIONAL_DOCUMENT' as DocumentType,
          sectionReference: nanoid() as string,
          uploadedAt: new Date().toISOString(),
        };
        addPendingUpload(id, fileUploadData);
        return newFileInfo;
      });

    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  }

  return (
    <Dialog
      size="md"
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
    >
      <Dialog.Heading>Upload Additional Documents</Dialog.Heading>
      <Dialog.Content>
        <FileUpload
          allowedFileTypes={['png', 'pdf', 'jpg', 'jpeg', 'doc', 'docx', 'csv', 'xls', 'xlsx']}
          maxFileSize={0}
          multiple
          label="Upload Additional Documents"
          onChange={(value) => {
            transformValue(value);
          }}
          size="lg"
          onRemoveFile={(file) => onRemoveFile(file.id)}
          value={files}
        >
          <>
            <FileUpload.Button>Browse Files</FileUpload.Button>
            <FileUpload.FileList>
              <FileList orientation="vertical">
                {files.map((file) => (
                  <FileList.Item key={file.id} value={file.id} name={file.file.name} intent="negative" />
                ))}
              </FileList>
            </FileUpload.FileList>
          </>
        </FileUpload>
      </Dialog.Content>
      <Dialog.Trigger>
        <Button emphasis="highlighted" size="md" intent="primary" leadIcon={PlusOutlineIcon}>
          Add Documents
        </Button>
      </Dialog.Trigger>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          intent="primary"
          width="fill"
          loading={isUpdatingApplication}
          disabled={files.length === 0}
          onClick={async () => {
            await updateApplicationAsync({ files: pendingFileUploadState }).then((response) => {
              if (response) {
                if (response.error) {
                  toast.negative(response.error.message);
                  return;
                } else {
                  if (files.length === 1) {
                    toast.positive('Successfully uploaded a document');
                  } else {
                    toast.positive('Successfully uploaded documents');
                  }
                  const filesUploaded = response.data.meta.files;
                  Object.keys(filesUploaded).forEach((fileId) => {
                    const fileData = pendingFileUploadState[fileId];
                    if (fileData) {
                      deleteUploadingFile(fileId);
                      addUploadedFiles(fileId, fileData);
                    }
                  });
                }
              }
            });
            setFiles([]);
          }}
        >
          Upload
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
