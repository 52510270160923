import { Dialog, Button, Text, Card, Flex, useToasts } from '@applyboard/crystal-ui';
import { ArrowRightOutlineIcon } from '@applyboard/ui-icons';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useDeferralRequestActivity } from './useDeferralRequestActivity';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

export function DeferralRequestDialog() {
  const { apiError } = useErrorHandler();
  const deferralRequest = useAppProcessing().application.meta.deferralRequest;
  const activity = useDeferralRequestActivity();
  const toasts = useToasts();

  const onDenyClick = () => {
    activity
      .processDeferralRequest({ accept: false })
      .then(() => {
        toasts.positive('The deferral request has been denied');
        activity.completeProcess();
      })
      .catch(() => {
        apiError();
      });
  };

  const onAcceptClick = () => {
    if (deferralRequest!.requestedFromState.code === 'INITIAL_OFFER') activity.startPreOffer();
    else
      activity
        .processDeferralRequest({ accept: true })
        .then(() => {
          toasts.positive(
            `The application intake has been updated from ${deferralRequest!.requestedFromIntakeTerm.name} to ${
              deferralRequest!.programIntakeTerm.name
            }`
          );
          activity.completeProcess();
        })
        .catch(() => {
          apiError();
        });
  };

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) activity.completeProcess();
      }}
    >
      <Dialog.Heading>Deferral Request</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={4} direction="column">
          <Text>Please review the deferral request below:</Text>
          <Card emphasis="highlighted">
            <Flex gap={2}>
              <Text variant="labelM" contrast="high">
                {deferralRequest!.requestedFromIntakeTerm.name}
              </Text>
              <ArrowRightOutlineIcon color="secondary" />
              <Text variant="labelM" contrast="high">
                {deferralRequest!.programIntakeTerm.name}
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text variant="labelM">Reason for request:</Text>
              <Text>{Object.values(deferralRequest!.reason)[0]}</Text>
            </Flex>
          </Card>
          <Text>
            Once the deferral is approved, the application will move back to its previous status of{' '}
            {deferralRequest!.requestedFromState.label}.
            {deferralRequest!.requestedFromState.code === 'INITIAL_OFFER' &&
              ' In addition, you will be prompted to issue a new pre-offer letter in the next step.'}
          </Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          intent="negative"
          emphasis="highlighted"
          width="fill"
          onClick={onDenyClick}
          loading={activity.isBusy && !activity.isAccepting}
          disabled={activity.isBusy && activity.isAccepting}
        >
          Deny
        </Button>
        <Button
          intent="primary"
          onClick={onAcceptClick}
          width="fill"
          loading={activity.isBusy && activity.isAccepting}
          disabled={activity.isBusy && !activity.isAccepting}
        >
          Approve
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
