import { DocumentOutlineIcon } from '@applyboard/ui-icons';
import { UploadAdditionalDocumentsDialog } from '../ApplicationProcessing/UploadAdditionalDocuments/UploadAdditionalDocumentsDialog';
import { UploadDocumentsList } from '../ApplicationProcessing/UploadAdditionalDocuments/DocumentsList';
import { ApplicationFileUpload } from '../../context/ApplicationFileUploadProvider';
import { Flex, Text } from '@applyboard/crystal-ui';
import { Card } from './Card';

export function UploadAdditionalDocuments() {
  return (
    <ApplicationFileUpload>
      <Card hideChevron id="uploadAdditionalDocuments" icon={DocumentOutlineIcon} title="Upload Additional Documents">
        <Flex gap={8} direction="column">
          <Flex.Item>
            <Text variant="bodyM" contrast="low">
              You can upload additional documents such as Provincial Attestation Letter, Orientation Schedules, Payment
              Confirmation Receipts, etc.
            </Text>
          </Flex.Item>
          <Flex.Item>
            <UploadDocumentsList />
          </Flex.Item>
          <Flex.Item>
            <UploadAdditionalDocumentsDialog />
          </Flex.Item>
        </Flex>
      </Card>
    </ApplicationFileUpload>
  );
}
