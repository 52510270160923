import { useApplicationFileUploadContext } from '~/pages/ApplicationDetails/context/ApplicationFileUploadProvider';
import { RemoveDocument } from './RemoveDocumentDialog';
import { Flex, Text } from '@applyboard/crystal-ui';
import moment from 'moment';

export function UploadDocumentsList() {
  const { uploadedFiles } = useApplicationFileUploadContext();

  return (
    <>
      <Flex gap={2} direction="row">
        {Object.keys(uploadedFiles).map((id) => (
          <Flex gap={2} direction="column" key={id} wrap>
            <Flex.Item key={`${id}-document`}>
              {uploadedFiles && <RemoveDocument key={`${id}-file`} document={uploadedFiles[id]!} fileId={id} />}
            </Flex.Item>
            <Flex.Item key={`${id}-text`}>
              <Text variant="labelS" contrast="mid">
                {uploadedFiles ? 'Uploaded on ' + moment(uploadedFiles[id]!.uploadedAt).format('MMM D, YYYY') : ''}
              </Text>
            </Flex.Item>
          </Flex>
        ))}
      </Flex>
    </>
  );
}
