import { useAppProcessing, useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';

import { ActionGroup, Action } from './ActionGroup';
import { DeclineDialog } from './Decline/DeclineDialog';
import { PreOfferDialog } from './IssuePreOffer/PreOfferDialog';
import { AdditionalDocumentRequest } from './AdditionalDocumentRequest';
import { WaitlistLetterDialog } from './Waitlisted/WaitlistLetterDialog';
import { FinalOfferDialog } from './IssueFinalOffer/FinalOfferDialog';
import { usePassScreeningActivity } from './passScreening';
import { DeferralRequestDialog } from './DeferralRequest/DefferalRequestDialog';
import { DismissDocumentRequestsDialog } from './DismissDocumentRequests/DismissDocumentRequestsDialog';

export function ApplicationProcessing() {
  const additionalDocumentRequest = useAdditionalDocumentRequest();
  const { activeProcess, startProcess, application, setNextProcess } = useAppProcessing();
  const { submitPassScreening } = usePassScreeningActivity();

  function onAction(action: Action) {
    if (action.code === 'REQUEST_DOCUMENTS') {
      return additionalDocumentRequest.enableFeature();
    }

    // If there are pending documents, dismiss them before proceeding into the nextStatus in the array
    // To proceed, we need to remember what is the triggering action
    if (
      ['INITIAL_OFFER', 'FINAL_OFFER_ISSUED', 'WAITLISTED', 'READY_FOR_ASSESSMENT', 'DECLINED'].includes(
        action.nextStatus
      ) &&
      application.meta.additionalDocumentRequest?.documents.some((f) => f.status.code === 'PENDING')
    ) {
      switch (action.nextStatus) {
        case 'INITIAL_OFFER':
          setNextProcess('issuePreOffer');
          break;
        case 'FINAL_OFFER_ISSUED':
          setNextProcess('issueFinalOffer');
          break;
        case 'WAITLISTED':
          setNextProcess('waitlisted');
          break;
        case 'READY_FOR_ASSESSMENT':
          setNextProcess('passScreening');
          break;
        case 'DECLINED':
          setNextProcess('decline');
          break;
      }
      return startProcess('dismissDocumentRequest');
    }

    // no pending documents, proceed as usual
    switch (action.nextStatus) {
      case 'DECLINED':
        return startProcess('decline');
      case 'INITIAL_OFFER':
        return startProcess('issuePreOffer');
      case 'FINAL_OFFER_ISSUED':
        return startProcess('issueFinalOffer');
      case 'WAITLISTED':
        return startProcess('waitlisted');
      case 'READY_FOR_ASSESSMENT':
        return submitPassScreening();
      case 'DOCUMENTS_REQUESTED_IN_ASSESSMENT':
      case 'DOCUMENTS_REQUESTED_IN_SCREENING':
        return additionalDocumentRequest.enableFeature();
    }
  }

  if (activeProcess === 'additionalDocumentRequest') return <AdditionalDocumentRequest />;

  return (
    <>
      {activeProcess === 'decline' && <DeclineDialog />}
      {activeProcess === 'issuePreOffer' && <PreOfferDialog applicationId={application.id} />}
      {activeProcess === 'issueFinalOffer' && <FinalOfferDialog applicationId={application.id} />}
      {activeProcess === 'waitlisted' && <WaitlistLetterDialog applicationId={application.id} />}
      {activeProcess === 'deferralRequestPreOffer' && (
        <PreOfferDialog applicationId={application.id} patchDeferralRequest />
      )}
      {activeProcess === 'deferralRequest' && <DeferralRequestDialog />}
      {activeProcess === 'dismissDocumentRequest' && <DismissDocumentRequestsDialog />}
      {application.status.actionGroups.some((g) => g.actions.length > 0) &&
        application.status.actionGroups.map((actionGroup) => (
          <ActionGroup key={actionGroup.title} actionGroup={actionGroup} onAction={onAction} />
        ))}
    </>
  );
}
